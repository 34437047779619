var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-goods--wrapper" }, [
    _c("div", { staticClass: "clo-goods" }, [
      _c("img", {
        staticClass: "clo-goods__image",
        attrs: { src: _vm.WebBoss, alt: "" }
      }),
      _c("div", { staticClass: "clo-goods__subtitle" }, [
        _vm._v("- web 老板端 -")
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-goods__content" }, [
      _c("div", [
        _vm._v(
          "1.报表查询：库存数量，库存金额，商品毛利，包括业务和客户销售金额。"
        )
      ]),
      _c("div", [
        _vm._v(
          "2.数据分析：帮老板有效分析单品利润排行、分析客户销售数据、分析业务员销售情况，使得公司在大环境不好的状况下，营业额利润逆势而上!"
        )
      ]),
      _c("div", [_vm._v("3.库存管理")]),
      _c("div", [
        _vm._v(
          "①.查看入库记录②.盘点记录和出库记录做到多库房管 理，库管扫码入库。"
        )
      ]),
      _c("div", [
        _vm._v(
          "4.预付款/欠款管理：根据客户名称，轻松输入预付款/欠款金额，并可查看到变更明细。"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }