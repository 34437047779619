var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "clo", staticClass: "clo", on: { scroll: _vm.scrollEvent } },
    [
      _c(
        "div",
        { staticClass: "clo-header" },
        [
          _c("clo-sub-top"),
          _c("clo-top", {
            ref: "top",
            attrs: { headerFixed: _vm.headerFixed, actived: _vm.cloTopActived },
            on: {
              "update:actived": function($event) {
                _vm.cloTopActived = $event
              }
            }
          }),
          _vm.headerFixed
            ? _c("div", { staticStyle: { height: "56px" } })
            : _vm._e(),
          _c("img", {
            directives: [{ name: "imageError", rawName: "v-imageError" }],
            staticClass: "clo-header__switch--image",
            attrs: { src: _vm.HeaderImg, alt: "" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "clo-body" },
        [
          _c("clo-about-us"),
          _c("clo-about-us-navigation"),
          _c("clo-title", {
            attrs: { img: _vm.Goods, bg: "grey", mode: "big" }
          }),
          _c("clo-goods-app"),
          _c("clo-goods-web"),
          _c("clo-goods-app-staff"),
          _c("clo-goods-factory"),
          _c("clo-goods-miniprogram"),
          _c("clo-goods-gift"),
          _c("div", {
            staticStyle: { height: "20px", "background-color": "#fff" }
          }),
          _c("clo-title", {
            attrs: { img: _vm.TitleCompany, mode: "small", bg: "grey" }
          }),
          _c("clo-company-history")
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "clo--footer" },
        [
          _c("clo-title", { attrs: { img: _vm.TitleMessage, mode: "normal" } }),
          _c("clo-send-message"),
          _c("div", { staticStyle: { height: "10px" } }),
          _c("clo-title", { attrs: { img: _vm.TitleApp, mode: "normal" } }),
          _c("clo-load-app"),
          _c("div", { staticStyle: { height: "10px" } }),
          _c("clo-title", { attrs: { img: _vm.TitleLink, mode: "normal" } }),
          _c("clo-link-us"),
          _c("clo-record")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }