<template>
  <div class="clo-sub-top--wrapper">
    <div class="clo-sub-top">
      <a class="clo-sub-top--business">业务电话：15653337788</a>
      <span class="clo-sub-top--service">客服电话：0576-89881088</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-sub-top--wrapper {
  @include flex-row(space-between);
  @include font-init(13px,#fff,500);
  width: 100%;
  height: 40px;
  background-color: #252E37;
  .clo-sub-top {
    @include flex-row(space-between);
    width: 100%;
    height: 100%;
    padding: 0 30px;
  }
}
</style>
