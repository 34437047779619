import { render, staticRenderFns } from "./about-us-navigation.vue?vue&type=template&id=91c682e0&scoped=true&"
import script from "./about-us-navigation.vue?vue&type=script&lang=js&"
export * from "./about-us-navigation.vue?vue&type=script&lang=js&"
import style0 from "./about-us-navigation.vue?vue&type=style&index=0&id=91c682e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91c682e0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/songtao/yxb/web-project/web-helper/official-website/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('91c682e0')) {
      api.createRecord('91c682e0', component.options)
    } else {
      api.reload('91c682e0', component.options)
    }
    module.hot.accept("./about-us-navigation.vue?vue&type=template&id=91c682e0&scoped=true&", function () {
      api.rerender('91c682e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/page/mobile/components/about-us-navigation.vue"
export default component.exports