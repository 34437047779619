<template>
  <div class="clo" ref="clo" @scroll="scrollEvent">
    <div class="clo-header">
      <clo-sub-top></clo-sub-top>
      <clo-top ref="top" :headerFixed="headerFixed" :actived.sync="cloTopActived"></clo-top>
      <div v-if="headerFixed" style="height:56px"></div>
      <img class="clo-header__switch--image" :src="HeaderImg" alt="" v-imageError>
    </div>
    <div class="clo-body">
      <clo-about-us></clo-about-us>
      <clo-about-us-navigation></clo-about-us-navigation>
      <clo-title :img="Goods" bg="grey" mode="big"></clo-title>
      <clo-goods-app></clo-goods-app>
      <clo-goods-web></clo-goods-web>
      <clo-goods-app-staff></clo-goods-app-staff>
      <clo-goods-factory></clo-goods-factory>
      <clo-goods-miniprogram></clo-goods-miniprogram>
      <clo-goods-gift></clo-goods-gift>
      <div style="height: 20px;background-color: #fff;"></div>
      <clo-title :img="TitleCompany" mode="small" bg="grey"></clo-title>
      <clo-company-history></clo-company-history>
    </div>
    <div class="clo--footer">
      <clo-title :img="TitleMessage" mode="normal"></clo-title>
      <clo-send-message></clo-send-message>
      <div style="height: 10px;"></div>
      <clo-title :img="TitleApp" mode="normal"></clo-title>
      <clo-load-app></clo-load-app>
      <div style="height: 10px;"></div>
      <clo-title :img="TitleLink" mode="normal"></clo-title>
      <clo-link-us></clo-link-us>
      <clo-record></clo-record>
    </div>
  </div>
</template>

<script>
import Goods from '@/assets/mobile/goods@2x.png';
import TitleCompany from '@/assets/mobile/title-company@2x.png';
import TitleMessage from '@/assets/mobile/title-message@2x.png';
import TitleApp from '@/assets/mobile/title-app@2x.png';
import TitleLink from '@/assets/mobile/title-link@2x.png';

import Service from '@/assets/index/service.png';
import Company from '@/assets/index/company.png';
import Merchant from '@/assets/index/merchant.png';
import BackTop from '@/assets/index/back-top.png';

import HeaderImg from '@/assets/mobile/header-img@2x.png';

import Banner from '@/assets/index/banner@2x.jpg';
import Banner1 from '@/assets/index/banner1@2x.jpg';
import Banner2 from '@/assets/index/banner2@2x.jpg';
import Number03 from '@/assets/index/03@2x.png';

import Util from '@/util/util';
import CloSubTop from './components/sub-top.vue';
import CloTop from './components/top.vue';
import CloAboutUs from './components/about-us.vue';
import CloLinkUs from './components/link-us.vue';
import CloAboutUsNavigation from './components/about-us-navigation.vue';
import CloLoadApp from './components/load-app.vue';
import CloCompanyHistory from './components/company-history.vue';
import CloSendMessage from './components/send-message.vue';
import CloGoodsApp from './components/goods-app.vue';
import CloGoodsWeb from './components/goods-web.vue';
import CloGoodsAppStaff from './components/goods-app-staff.vue';
import CloGoodsFactory from './components/goods-factory.vue';
import CloGoodsGift from './components/goods-gift.vue';
import CloGoodsMiniprogram from './components/goods-miniprogram.vue';
import CloTitle from './components/title.vue';
import CloRecord from './components/record.vue';

export default {
  components: {
    CloSubTop,
    CloTop,
    CloAboutUs,
    CloAboutUsNavigation,
    CloCompanyHistory,
    CloSendMessage,
    CloGoodsApp,
    CloGoodsWeb,
    CloGoodsAppStaff,
    CloGoodsFactory,
    CloGoodsGift,
    CloGoodsMiniprogram,
    CloLoadApp,
    CloTitle,
    CloLinkUs,
    CloRecord,
  },
  data() {
    return {
      Goods,
      TitleCompany,
      TitleMessage,
      TitleApp,
      TitleLink,

      Service,
      Company,
      Merchant,
      BackTop,

      HeaderImg,

      bannerList: [
        Banner,
        Banner1,
        Banner2,
      ],
      Number03,
      offsetTop: 0,
      offsetHeight: 0,
      headerFixed: false,
      cloTopActived: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 获取 top 的 dom
      const header = this.$refs.top.$refs.cloTop;
      this.offsetTop = header.offsetTop;
      this.offsetHeight = header.offsetHeight;
    });
    const isMobile = Util.judgeService();
    if (!isMobile) this.$router.push('/pc');
  },
  methods: {
    showServiceImage() {
      this.$refs.serviceImageDialog.show(
        '客服微信二维码',
        null,
        () => {
          this.$refs.serviceImageDialog.hide();
        },
        () => {
          this.$refs.serviceImageDialog.hide();
        },
      );
    },
    indexGoEntrance(num) {
      const obj = {
        0: 'https://web.tzyxb.com/webfactory/#/',
        1: 'https://web.tzyxb.com/webboss/#/',
      };
      window.open(obj[num]);
    },
    updataCloTopActived(num) {
      this.cloTopActived = num;
    },
    scrollEvent() {
      const { clo } = this.$refs;
      const { scrollTop } = clo;

      this.headerFixed = scrollTop > (this.offsetTop);
      if (scrollTop > 699 && scrollTop < 2079) {
        this.cloTopActived = 0;
      } else if (scrollTop > 2079 && scrollTop < 5683) {
        this.cloTopActived = 1;
      } else if (scrollTop > 5683 && scrollTop < 6520) {
        this.cloTopActived = 2;
      } else if (scrollTop > 6520 && scrollTop < 6735) {
        this.cloTopActived = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: #F7F7F7;
  .clo__box--bottom {
    width: 1200px;
    height: 150px;
    border-left: 2px solid #333;
    margin: 0 auto;
    .bottom__content {
      @include flex-row(flex-start, flex-end);
      height: 100%;
      &--icon {
        width: 70px;
        height: 59px;
        margin-left: 19px;
        .icon__image {
          width: 100%;
          height: 100%;
        }
      }
      &--english {
        @include font-init(53px,#333333,800);
        margin-left: 30px;
        &.bottom__content--english-thin {
          @include font-init(53px,#999,400);
        }
        span {
          @include font-init(53px,#6B6B6B,300);
          margin-left: 8px;
        }
      }
      &--subtitle {
        @include font-init(24px,#333333,800);
        margin-left: 29px;
        margin-bottom: 7px;
        text-align: left;
      }
    }
  }
  &-header {
    ::v-deep .el-carousel__indicator {
      width: 60px;
      height: 8px;
      &.is-active {
        .el-carousel__button {
          width: 60px;
          height: 8px;
          border-radius: 4px;
          background-color: #FA8E19;
        }
      }
      .el-carousel__button {
        width: 60px;
        height: 8px;
        border-radius: 4px;
        background-color: #E2E2E2;
      }
    }
  }
  .clo-header__switch--image {
    width: 100%;
    height: 480px;
    object-fit: cover;
    display: block;
  }
  .service {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 18px;
    bottom: 604px;
    z-index: 99;
    &__image {
      width: 100%;
      height: 100%;
    }
  }
  .company {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 18px;
    bottom: 484px;
    z-index: 99;
    &__image {
      width: 100%;
      height: 100%;
    }
    &__text {
      @include flex-row(center);
      width: 100%;
      position: absolute;
      bottom: 3px;
      color: #fff;
      font-size: 12px;
    }
  }
  .merchant {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 18px;
    bottom: 364px;
    z-index: 99;
    &__image {
      width: 100%;
      height: 100%;
    }
    &__text {
      @include flex-row(center);
      width: 100%;
      position: absolute;
      bottom: 3px;
      color: #fff;
      font-size: 12px;
    }
  }
  .serviceImageDialog__image {
    width: 337px;
    height: 448px;
  }
  ::v-deep .el-backtop {
    right: 18px !important;
    bottom: 240px !important;
    width: 100px;
    height: 100px;
  }
}
</style>
