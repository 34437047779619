<template>
  <div class="clo-goods--wrapper">
    <div class="clo-goods">
      <img class="clo-goods__image" :src="WebBoss" alt="">
      <div class="clo-goods__subtitle">- web 老板端 -</div>
      <div class="clo-goods__content">
        <div>1.报表查询：库存数量，库存金额，商品毛利，包括业务和客户销售金额。</div>
        <div>2.数据分析：帮老板有效分析单品利润排行、分析客户销售数据、分析业务员销售情况，使得公司在大环境不好的状况下，营业额利润逆势而上!</div>
        <div>3.库存管理</div>
        <div>①.查看入库记录②.盘点记录和出库记录做到多库房管 理，库管扫码入库。</div>
        <div>4.预付款/欠款管理：根据客户名称，轻松输入预付款/欠款金额，并可查看到变更明细。</div>
      </div>
    </div>
  </div>
</template>

<script>
import WebBoss from '@/assets/mobile/webboss@2x.png';

export default {
  data() {
    return {
      WebBoss,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-goods--wrapper {
  padding: 0 26px 53px 22px;
  box-sizing: border-box;
  background-color: #FFF;
  .clo-goods {
    @include flex-column(center, flex-start);
    width: 100%;
    height: 100%;
    &__image {
      width: 100%;
      height: 100%;
      margin-top: 70px;
    }
    &__subtitle {
      @include font-init(19px, #121212, 700);
      @include flex-row(center, center);
      width: 100%;
      margin-top: 26px;
    }
    &__content {
      @include flex-column(flex-start, flex-start);
      @include font-init(16px, #121212, 400);
      margin-top: 25px;
      line-height: 27px;
      letter-spacing: -1px;

      text-align: left;
    }
  }
}
</style>
