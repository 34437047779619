var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-goods--wrapper" }, [
    _c("div", { staticClass: "clo-goods" }, [
      _c("img", {
        staticClass: "clo-goods__image",
        attrs: { src: _vm.ManayPage, alt: "" }
      }),
      _c("div", { staticClass: "clo-goods__subtitle" }, [
        _vm._v("- 赠品选购 -")
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-goods__content" }, [
      _c("div", [_vm._v("1.新品不好推广")]),
      _c("div", [_vm._v("2.临期产品不好处理 ")]),
      _c("div", [_vm._v("3.自己找赠品没有时间")]),
      _c("div", [_vm._v("4.不知道怎么做好绑赠销售")]),
      _c("div", [_vm._v("5.赠品质量不好把控")]),
      _c("div", [_vm._v("6.找不到价格低的赠品")]),
      _c("div", [_vm._v("7.选品的过程累和煎熬")]),
      _c("div", [_vm._v("8.没有耐心逛商场选赠品")]),
      _c("div", [_vm._v("9.去到一个商场眼花缭乱")]),
      _c("div", [_vm._v("10.没有一个系统的赠品平台")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }