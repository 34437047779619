<template>
  <div class="clo-send-message">
    <div class="clo-send-message__form">
      <div class="clo-send-message__form--item">
        <img class="item__icon" :src="MessageName" alt="" srcset="">
        <input class="item__input" v-model="dataForm.linkman" type="text" placeholder="姓名">
      </div>
      <div class="clo-send-message__form--item">
        <img class="item__icon" :src="MessagePhone" alt="" srcset="">
        <input class="item__input" v-model="dataForm.tel" type="text" placeholder="手机号">
      </div>
      <div class="clo-send-message__form--item">
        <img class="item__icon" :src="MessageMessage" alt="" srcset="">
        <input class="item__input" v-model="dataForm.content" type="text" placeholder="留言">
      </div>
      <div class="clo-send-message__form--submit" @click="handleSubmit">提交</div>
    </div>
  </div>
</template>

<script>
import MessageName from '@/assets/mobile/message-name@2x.png';
import MessagePhone from '@/assets/mobile/message-phone@2x.png';
import MessageMessage from '@/assets/mobile/message-message@2x.png';

export default {
  data() {
    return {
      MessageName,
      MessagePhone,
      MessageMessage,

      dataForm: {
        tel: '',
        linkman: '',
        content: '',
      },
      isDisabled: false,
    };
  },
  methods: {
    async handleSubmit() {
      if (this.isDisabled) {
        return;
      }
      this.isDisabled = true;
      if (!this.dataForm.linkman) {
        this.$message.error('请先填写姓名');
        this.isDisabled = false;
        return;
      }
      if (!this.dataForm.tel) {
        this.$message.error('请填写电话');
        this.isDisabled = false;
        return;
      }
      if (!this.dataForm.content) {
        this.$message.error('请填写留言');
        this.isDisabled = false;
        return;
      }
      const reqForm = { ...this.dataForm };
      const [err, res] = await this.$util.to(this.$api.IndexMessages_addIndexMessages(reqForm));
      this.resetForm();
      this.isDisabled = false;
      if (res) {
        this.$message.success('提交成功！');
      }
    },
    resetForm() {
      this.dataForm = {
        tel: '',
        linkman: '',
        content: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.disabled {
  background-color: rgba($color: #fff, $alpha: 0.4) !important;
  color: rgba($color: #000000, $alpha: 0.2) !important;
  cursor: default !important;
}
.clo-send-message {
  width: 100%;
  background-color: #FFF;
  &__form {
    @include flex-column(space-between, center);
    width: 100%;
    height: 100%;
    padding: 0 28px;
    padding-bottom: 40px;
    box-sizing: border-box;
    &--item {
      @include flex-row(flex-start, center);
      width: 100%;
      height: 40px;
      background-color: #F7F7F7;
      border-radius: 10px;
      margin-top: 24px;
      .item__icon {
        width: 20px;
        height: 20px;
        margin-left: 15px;
      }
      .item__input {
        margin-left: 15px;
        background-color: #F7F7F7;
        border: none;
        width: 100%;
        height: 100%;
      }
      // .item__input {
      //   width: 300px;
      //   height: 30px;
      //   background-color: #F39800;
      //   border: 0;
      //   border-bottom: 1px solid #fff;
      //   outline: none;
      //   color: #fff;
      //   &::placeholder {
      //     @include font-init(16px, #fff,500);
      //   }
      // }
    }
    &--submit {
      @include font-init(15px, #FFF, 600);
      @include flex-row(center, center);
      width: 100%;
      height: 40px;
      border-radius: 10px;
      background-color: #F36D00;
      margin-top: 24px;
    }
  }
}
</style>
