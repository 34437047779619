var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-record--wrapper" }, [
      _c("div", { staticClass: "clo-record" }, [
        _c("div", { staticClass: "clo-record--business" }, [
          _vm._v("© 2018-2019 yxbabc.com 版权所有")
        ]),
        _c(
          "a",
          {
            staticClass: "clo-record--service",
            attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" }
          },
          [_vm._v("ICP证：浙ICP备18040182号-1")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }