import { render, staticRenderFns } from "./company-history.vue?vue&type=template&id=b248451e&scoped=true&"
import script from "./company-history.vue?vue&type=script&lang=js&"
export * from "./company-history.vue?vue&type=script&lang=js&"
import style0 from "./company-history.vue?vue&type=style&index=0&id=b248451e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b248451e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/songtao/yxb/web-project/web-helper/official-website/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b248451e')) {
      api.createRecord('b248451e', component.options)
    } else {
      api.reload('b248451e', component.options)
    }
    module.hot.accept("./company-history.vue?vue&type=template&id=b248451e&scoped=true&", function () {
      api.rerender('b248451e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/page/mobile/components/company-history.vue"
export default component.exports