<template>
  <div class="clo-goods--wrapper">
    <div class="clo-goods">
      <img class="clo-goods__image" :src="AppBoss" alt="">
      <div class="clo-goods__subtitle">- APP 老板端 -</div>
      <div class="clo-goods__content">
        <div>云销宝能为你带来：强大的销售分析统计；客户、业务</div>
        <div>员何时下单，何时付款，一键追踪。每日，每月销售数</div>
        <div>据精准统计。从此不用来回查账，在家实时一键查询；</div>
        <div>完善的库存管理系统：让所有的进出库记录有迹可查，</div>
        <div>帮您实现足不出户轻松管理；精准的人员定位，追踪能</div>
        <div>力，无须亲自出门，即可了解业务员所在位置，今日行</div>
        <div>径路线，轻松管理在外业务员；一店一品一价功能，帮</div>
        <div>助记录每个店铺不同商品的售价，实现傻瓜式售卖；</div>
        <div>云销宝帮您永不丢单、漏单，实现轻松管理。我们保证</div>
        <div>数据保存。助您实现无纸化线上办公。</div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBoss from '@/assets/mobile/appboss@2x.png';

export default {
  data() {
    return {
      AppBoss,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-goods--wrapper {
  padding: 0 26px 45px 22px;
  box-sizing: border-box;
  background-color: #F7F7F7;
  .clo-goods {
    @include flex-column(center, flex-start);
    width: 100%;
    height: 100%;
    &__title {
      @include flex-row(space-between, flex-end);
      width: 100%;
      padding-top: 37px;
      &--left {
        @include font-init(21px, #121212, 700);
        .log-img {
          width: 260px;
          height: 34px;
        }
      }
      &--right {
        background-color: #F39900;
        width: 29px;
        height: 7px;
      }
    }
    &__image {
      width: 100%;
      height: 100%;
      margin-right: 30px;
      margin-top: 55px;
      margin-left: -70px;
    }
    &__image--normal {
      width: 100%;
      height: 100%;
      margin-top: 55px;
    }
    &__subtitle {
      @include font-init(19px, #121212, 700);
      @include flex-row(center, center);
      width: 100%;
      margin-top: 26px;
    }
    &__content {
      @include flex-column(flex-start, flex-start);
      @include font-init(16px, #121212, 400);
      margin-top: 25px;
      line-height: 27px;
      letter-spacing: -1px;
      text-align: left;
    }
  }
}
</style>
