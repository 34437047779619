<template>
  <div class="link-us--wrapper">
    <div class="link-us__item">
      <div class="link-us__label">地址：</div>
      <div class="link-us__value">
        <div>浙江·台州云销宝科技有限公司</div>
        <div>浙江·台州市黄岩区科新路25号</div>
      </div>
    </div>
    <div class="link-us__item">
      <div class="link-us__label">网址：</div>
      <div class="link-us__value">www.yxbabc.com</div>
    </div>
    <div class="link-us__item">
      <div class="link-us__label">客服电话：</div>
      <div class="link-us__value">0576-89881088</div>
    </div>
    <div class="link-us__item">
      <div class="link-us__label">业务电话：</div>
      <div class="link-us__value">15653337788</div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.link-us--wrapper {
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  background-color: #FFF;
  .link-us__item {
    @include flex-row(space-between, flex-start);
    width: 100%;
    font-size: 16px;
    color: #121212;
    padding-top: 23px;
  }
  .link-us__label {
    white-space: nowrap;
  }
}
</style>
