<template>
  <div class="clo-company-history--wrapper">
    <div class="clo-company-history-item">
      <img class="clo-company-history-item__img" :src="Company01" alt="" srcset="">
      <img class="clo-company-history-item__img" :src="Company02" alt="" srcset="">
      <img class="clo-company-history-item__img" :src="Company03" alt="" srcset="">
      <img class="clo-company-history-item__img" :src="Company04" alt="" srcset="">
      <img class="clo-company-history-item__img" :src="Company05" alt="" srcset="">
    </div>
  </div>
</template>

<script>
import Company01 from '@/assets/mobile/company01@2x.png';
import Company02 from '@/assets/mobile/company02@2x.png';
import Company03 from '@/assets/mobile/company03@2x.png';
import Company04 from '@/assets/mobile/company04@2x.png';
import Company05 from '@/assets/mobile/company05@2x.png';

export default {
  data() {
    return {
      Company01,
      Company02,
      Company03,
      Company04,
      Company05,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-company-history--wrapper {
  width: 100%;
  .clo-company-history-item {
    width: 100%;
    position: relative;
    &__img {
      width: 100%;
      height: 216px;
      margin-top: 25px;
    }
  }
}
</style>
