<template>
  <div class="clo-goods--wrapper">
    <div class="clo-goods">
      <img class="clo-goods__image" :src="Factory" alt="">
      <div class="clo-goods__subtitle">- 厂商端 -</div>
      <div class="clo-goods__content">
        <div>1.及时掌握经销商的库存和销售情况，不能简单粗暴的压货。</div>
        <div>2.积极调整销售策略，开发更多能让你的合作伙伴和员工赚钱的产品和方式。</div>
        <div>3.了解你的经销商的财务状况，不要只是对上预存款，对下是赊欠+退货+死账+垫付费用+营销所产生的各种费用，最终把经销商压死了。</div>
        <div>4.不要让经销商给你虚假的数据，我们要真实的汇总数据来判断我们的产品走向。</div>
      </div>
    </div>
  </div>
</template>

<script>
import Factory from '@/assets/mobile/factory@2x.png';

export default {
  data() {
    return {
      Factory,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-goods--wrapper {
  padding: 0 26px 53px 22px;
  box-sizing: border-box;
  background-color: #FFF;
  .clo-goods {
    @include flex-column(center, flex-start);
    width: 100%;
    height: 100%;
    &__image {
      width: 100%;
      height: 100%;
      margin-top: 70px;
    }
    &__subtitle {
      @include font-init(19px, #121212, 700);
      @include flex-row(center, center);
      width: 100%;
      margin-top: 26px;
    }
    &__content {
      @include flex-column(flex-start, flex-start);
      @include font-init(16px, #121212, 400);
      margin-top: 27px;
      line-height: 27px;
      letter-spacing: -1px;

      text-align: left;
    }
  }
}
</style>
