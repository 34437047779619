var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-goods--wrapper" }, [
    _c("div", { staticClass: "clo-goods" }, [
      _c("img", {
        staticClass: "clo-goods__image",
        attrs: { src: _vm.AppStaff, alt: "" }
      }),
      _c("div", { staticClass: "clo-goods__subtitle" }, [
        _vm._v("- APP 业务员 -")
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-goods__content" }, [
      _c("div", [_vm._v("定位考勤；巡店拍照；傻瓜式销售；")]),
      _c("div", [_vm._v("智能交款手机端下订单；库存变化；")]),
      _c("div", [_vm._v("盘点品项价格记忆；查看线路规划；交易查询")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }