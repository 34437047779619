var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-goods--wrapper" }, [
    _c("div", { staticClass: "clo-goods" }, [
      _c("img", {
        staticClass: "clo-goods__image",
        attrs: { src: _vm.MiniProgram, alt: "" }
      }),
      _c("div", { staticClass: "clo-goods__subtitle" }, [
        _vm._v("- 客户小程序 -")
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-goods__content" }, [
      _c("div", [_vm._v("把自己的店铺开到微信上，下游客户直接打开微信就可")]),
      _c("div", [_vm._v("以进入店铺下单，这简直就是网购式订货.")]),
      _c("div", [_vm._v("我们可以做的无纸化办公;客户的电子单据可以永久的")]),
      _c("div", [_vm._v("保存,永远不丢失,省去了纸质单据的不好储存的问题,你")]),
      _c("div", [_vm._v("也可以让你的客户扫下面的小程序码直接扫码加入。")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }