<template>
  <div class="clo-about-us__navigation--wrapper">
    <div class="clo-about-us__list" style="padding-top: 35px;">
      <div class="clo-about-us__list--item" v-for="(item, index) in navigationList" :key="index">
        <div class="item__icon">
          <img class="item__image" :src="item.icon" alt="" v-imageError>
        </div>
        <div class="item__text">{{item.text}}</div>
      </div>
    </div>
    <div class="clo-about-us__list" style="padding-top: 30px;">
      <div class="clo-about-us__list--item" v-for="(item, index) in navigationList1" :key="index">
        <div class="item__icon">
          <img class="item__image" :src="item.icon" alt="" v-imageError>
        </div>
        <div class="item__text">{{item.text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import cangkuguanli from '@/assets/mobile/mobilecangkuguanli@2x.png';
import changjiaguanli from '@/assets/mobile/mobilechangjiaguanli@2x.png';
import feiyongguanli from '@/assets/mobile/mobilefeiyongguanli@2x.png';
import kehuguanli from '@/assets/mobile/mobilekehuguanli@2x.png';
import shangpinguanli from '@/assets/mobile/mobileshangpinguanli@2x.png';
import yewuyuanguanli from '@/assets/mobile/mobileyewuyuanguanli@2x.png';

export default {
  data() {
    return {
      navigationList: [
        {
          icon: changjiaguanli,
          text: '厂家管理',
        },
        {
          icon: kehuguanli,
          text: '客户管理',
        },
        {
          icon: shangpinguanli,
          text: '商品管理',
        },
      ],
      navigationList1: [
        {
          icon: yewuyuanguanli,
          text: '业务员管理',
        },
        {
          icon: cangkuguanli,
          text: '仓库管理',
        },
        {
          icon: feiyongguanli,
          text: '费用管理',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-about-us__navigation--wrapper {
  width: 100%;
  padding: 0 45px;
  box-sizing: border-box;
  background-color: #F7F7F7;
  .clo-about-us__list {
    @include flex-row(space-between);
    width: 100%;
    flex-wrap: wrap;
    &--item {
      @include flex-column(space-between);
      height: 100%;
      width: 70px;
      .item__icon {
        width: 52px;
        height: 52px;
        .item__image {
          width: 100%;
          height: 100%;
        }
      }
      .item__text {
        color: #121212;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}
</style>
