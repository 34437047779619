var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link-us--wrapper" }, [
      _c("div", { staticClass: "link-us__item" }, [
        _c("div", { staticClass: "link-us__label" }, [_vm._v("地址：")]),
        _c("div", { staticClass: "link-us__value" }, [
          _c("div", [_vm._v("浙江·台州云销宝科技有限公司")]),
          _c("div", [_vm._v("浙江·台州市黄岩区科新路25号")])
        ])
      ]),
      _c("div", { staticClass: "link-us__item" }, [
        _c("div", { staticClass: "link-us__label" }, [_vm._v("网址：")]),
        _c("div", { staticClass: "link-us__value" }, [_vm._v("www.yxbabc.com")])
      ]),
      _c("div", { staticClass: "link-us__item" }, [
        _c("div", { staticClass: "link-us__label" }, [_vm._v("客服电话：")]),
        _c("div", { staticClass: "link-us__value" }, [_vm._v("0576-89881088")])
      ]),
      _c("div", { staticClass: "link-us__item" }, [
        _c("div", { staticClass: "link-us__label" }, [_vm._v("业务电话：")]),
        _c("div", { staticClass: "link-us__value" }, [_vm._v("15653337788")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }