<template>
  <div class="clo-top--wrapper" :class="{headerFixed: headerFixed}" ref="cloTop">
    <div class="clo-top">
      <div class="clo-top__logo">
        <img class="logo__image" :src="Logo" alt="">
      </div>
      <div class="clo-top__operate">
        <img class="clo-icon mg-r26" :src="Phone" alt="" @click="callPhone">
        <img class="clo-icon" :src="Group" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/logo.png';
import Phone from '@/assets/mobile/phone@2x.png';
import Group from '@/assets/mobile/group@2x.png';

export default {
  props: {
    headerFixed: {
      type: Boolean,
      default: false,
    },
    actived: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      Logo,
      Phone,
      Group,
    };
  },
  methods: {
    callPhone() {
      window.location.href = 'tel:0576-89881088';
    },
    goModelPage(num) {
      this.$emit('update:actived', num);
      const body = this.$parent.$refs.clo;
      const obj = {
        0: 699,
        1: 2079,
        2: 5683,
        3: 6520,
      };
      body.scrollTop = obj[num];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-top--wrapper.headerFixed {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
}
.clo-top--wrapper {
  @include flex-row(center);
  width: 100%;
  height: 56px;
  background-color: #FFFFFF;
  .clo-top {
    @include flex-row(space-between, center);
    width: 100%;
    height: 100%;
    padding: 0 16px;
    &__logo {
      width: 104px;
      height: 36px;
      .logo__image {
        width: 100%;
        height: 100%;
      }
    }
    &__operate {
      .clo-icon {
        width: 20px;
        height: 20px;
      }
      .mg-r26 {
        margin-right: 26px;
      }
    }
  }
}
</style>
