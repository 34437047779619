<template>
  <div class="clo-goods--wrapper">
    <div class="clo-goods">
      <img class="clo-goods__image" :src="ManayPage" alt="">
      <div class="clo-goods__subtitle">- 赠品选购 -</div>
      <div class="clo-goods__content">
        <div>1.新品不好推广</div>
        <div>2.临期产品不好处理 </div>
        <div>3.自己找赠品没有时间</div>
        <div>4.不知道怎么做好绑赠销售</div>
        <div>5.赠品质量不好把控</div>
        <div>6.找不到价格低的赠品</div>
        <div>7.选品的过程累和煎熬</div>
        <div>8.没有耐心逛商场选赠品</div>
        <div>9.去到一个商场眼花缭乱</div>
        <div>10.没有一个系统的赠品平台</div>
      </div>
    </div>
  </div>
</template>

<script>
import ManayPage from '@/assets/mobile/manypage@2x.png';

export default {
  data() {
    return {
      ManayPage,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-goods--wrapper {
  padding: 0 26px 53px 22px;
  box-sizing: border-box;
  background-color: #FFF;
  .clo-goods {
    @include flex-column(center, flex-start);
    width: 100%;
    height: 100%;
    &__image {
      width: 100%;
      height: 100%;
      margin-top: 70px;
    }
    &__subtitle {
      @include font-init(19px, #121212, 700);
      @include flex-row(center, center);
      width: 100%;
      margin-top: 26px;
    }
    &__content {
      @include flex-column(flex-start, flex-start);
      @include font-init(16px, #121212, 400);
      margin-top: 27px;
      line-height: 27px;
      letter-spacing: -1px;

      text-align: left;
    }
  }
}
</style>
