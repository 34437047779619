var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-about-us__navigation--wrapper" }, [
    _c(
      "div",
      {
        staticClass: "clo-about-us__list",
        staticStyle: { "padding-top": "35px" }
      },
      _vm._l(_vm.navigationList, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "clo-about-us__list--item" },
          [
            _c("div", { staticClass: "item__icon" }, [
              _c("img", {
                directives: [{ name: "imageError", rawName: "v-imageError" }],
                staticClass: "item__image",
                attrs: { src: item.icon, alt: "" }
              })
            ]),
            _c("div", { staticClass: "item__text" }, [
              _vm._v(_vm._s(item.text))
            ])
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "clo-about-us__list",
        staticStyle: { "padding-top": "30px" }
      },
      _vm._l(_vm.navigationList1, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "clo-about-us__list--item" },
          [
            _c("div", { staticClass: "item__icon" }, [
              _c("img", {
                directives: [{ name: "imageError", rawName: "v-imageError" }],
                staticClass: "item__image",
                attrs: { src: item.icon, alt: "" }
              })
            ]),
            _c("div", { staticClass: "item__text" }, [
              _vm._v(_vm._s(item.text))
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }