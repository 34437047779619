var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-company-history--wrapper" }, [
    _c("div", { staticClass: "clo-company-history-item" }, [
      _c("img", {
        staticClass: "clo-company-history-item__img",
        attrs: { src: _vm.Company01, alt: "", srcset: "" }
      }),
      _c("img", {
        staticClass: "clo-company-history-item__img",
        attrs: { src: _vm.Company02, alt: "", srcset: "" }
      }),
      _c("img", {
        staticClass: "clo-company-history-item__img",
        attrs: { src: _vm.Company03, alt: "", srcset: "" }
      }),
      _c("img", {
        staticClass: "clo-company-history-item__img",
        attrs: { src: _vm.Company04, alt: "", srcset: "" }
      }),
      _c("img", {
        staticClass: "clo-company-history-item__img",
        attrs: { src: _vm.Company05, alt: "", srcset: "" }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }