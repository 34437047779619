var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-goods--wrapper" }, [
    _c("div", { staticClass: "clo-goods" }, [
      _c("img", {
        staticClass: "clo-goods__image",
        attrs: { src: _vm.Factory, alt: "" }
      }),
      _c("div", { staticClass: "clo-goods__subtitle" }, [_vm._v("- 厂商端 -")]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-goods__content" }, [
      _c("div", [
        _vm._v("1.及时掌握经销商的库存和销售情况，不能简单粗暴的压货。")
      ]),
      _c("div", [
        _vm._v(
          "2.积极调整销售策略，开发更多能让你的合作伙伴和员工赚钱的产品和方式。"
        )
      ]),
      _c("div", [
        _vm._v(
          "3.了解你的经销商的财务状况，不要只是对上预存款，对下是赊欠+退货+死账+垫付费用+营销所产生的各种费用，最终把经销商压死了。"
        )
      ]),
      _c("div", [
        _vm._v(
          "4.不要让经销商给你虚假的数据，我们要真实的汇总数据来判断我们的产品走向。"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }