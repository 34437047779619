var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-goods--wrapper" }, [
    _c("div", { staticClass: "clo-goods" }, [
      _c("img", {
        staticClass: "clo-goods__image",
        attrs: { src: _vm.AppBoss, alt: "" }
      }),
      _c("div", { staticClass: "clo-goods__subtitle" }, [
        _vm._v("- APP 老板端 -")
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clo-goods__content" }, [
      _c("div", [_vm._v("云销宝能为你带来：强大的销售分析统计；客户、业务")]),
      _c("div", [_vm._v("员何时下单，何时付款，一键追踪。每日，每月销售数")]),
      _c("div", [_vm._v("据精准统计。从此不用来回查账，在家实时一键查询；")]),
      _c("div", [_vm._v("完善的库存管理系统：让所有的进出库记录有迹可查，")]),
      _c("div", [_vm._v("帮您实现足不出户轻松管理；精准的人员定位，追踪能")]),
      _c("div", [_vm._v("力，无须亲自出门，即可了解业务员所在位置，今日行")]),
      _c("div", [_vm._v("径路线，轻松管理在外业务员；一店一品一价功能，帮")]),
      _c("div", [_vm._v("助记录每个店铺不同商品的售价，实现傻瓜式售卖；")]),
      _c("div", [_vm._v("云销宝帮您永不丢单、漏单，实现轻松管理。我们保证")]),
      _c("div", [_vm._v("数据保存。助您实现无纸化线上办公。")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }