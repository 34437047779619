<template>
  <div class="clo-title--wrapper" :class="bgClassName">
    <div class="clo-title">
      <div class="clo-title--left">
        <img :class="imgClassName" :src="img" alt="">
      </div>
      <div class="clo-title--right"></div>
    </div>
  </div>
</template>

<script>
import Goods from '@/assets/mobile/goods@2x.png';

export default {
  data() {
    return {
      Goods,
    };
  },
  props: {
    img: {
      type: String,
    },
    bg: {
      type: String,
      default: 'white',
    },
    mode: {
      type: String,
      default: 'big',
    },
  },
  computed: {
    bgClassName() {
      return {
        'clo-title--grey': this.bg === 'grey',
        'clo-title--white': this.bg === 'white',
      };
    },
    imgClassName() {
      return {
        'log-img--big': this.mode === 'big',
        'log-img--normal': this.mode === 'normal',
        'log-img--small': this.mode === 'small',
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-title--grey {
  background-color: #F7F7F7;
}
.clo-title--white {
  background-color: #fff;
}
.log-img--big {
  width: 260px;
  height: 34px;
}
.log-img--normal {
  width: 205px;
  height: 34px;
}
.log-img--small {
  width: 150px;
  height: 34px;
}
.clo-title--wrapper {
  padding: 37px 26px 15px 22px;
  box-sizing: border-box;
  .clo-title {
    @include flex-row(space-between, flex-end);
    width: 100%;
    height: 100%;
    &--left {
      @include font-init(21px, #121212, 700);
    }
    &--right {
      background-color: #F39900;
      width: 29px;
      height: 7px;
    }
  }
}
</style>
