<template>
  <div class="clo-about-us--wrapper">
    <div class="clo-about-us">
      <div class="clo-about-us__title">
        <div class="clo-about-us__title--left">关于我们</div>
        <div class="clo-about-us__title--right"></div>
      </div>
      <div class="clo-about-us__content">
        <div>我们可以帮您实现......</div>
        <div>1.老板的遥控指挥</div>
        <div>2.商品的销量分析</div>
        <div>3.业务员的盈利对比</div>
        <div>4.客户的销量统计</div>
        <div>5.往来账的实时监控</div>
        <div>6.促销政策的智能管控</div>
        <div>7.费用的精准把握</div>
        <div>8.现场打印和订单无纸化分享</div>
        <div>9.工资的实时核算</div>
        <div>10.智能的价格管理体系</div>

        <div class="pos-re" style="margin-top: 25px;">
          <div class="abs-dot grey-dot"></div>
          <div>客户的位置、门头照片、欠款上限、一店一品一价、导航集于一身</div>
        </div>
        <div class="pos-re">
          <div class="abs-dot grey-dot"></div>
          <div>仓库商品的数量价值、临期、过期、生产日期图片的实时显示和杏询</div>
        </div>
        <div class="pos-re">
          <div class="abs-dot grey-dot"></div>
          <div>业务员的考勒工资拜访明细、实时路线、销量分析一键查询</div>
        </div>
        <div class="pos-re">
          <div class="abs-dot grey-dot"></div>
          <div>财务数据的精准分析、多维度的查看、准确的报表，帮您做准确的判断</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-about-us--wrapper {
  padding: 0 26px 0 22px;
  box-sizing: border-box;
  background-color: #F7F7F7;
  .clo-about-us {
    @include flex-column(center, flex-start);
    width: 100%;
    height: 100%;
    &__title {
      @include flex-row(space-between, flex-end);
      width: 100%;
      padding-top: 37px;
      &--left {
        @include font-init(21px, #121212, 700);
      }
      &--right {
        background-color: #F39900;
        width: 29px;
        height: 7px;
      }
    }
    &__content {
      @include flex-column(flex-start, flex-start);
      @include font-init(16px, #121212, 400);
      margin-top: 25px;
      line-height: 26px;

      text-align: left;
      .pos-re {
        position: relative;
        width: 100%;
        .grey-dot {
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #B2B2B2;
        }
        .abs-dot {
          position: absolute;
          top: 12px;
          left: -8px;
        }
      }
    }
  }
}
</style>
