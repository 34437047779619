import { render, staticRenderFns } from "./goods-app-staff.vue?vue&type=template&id=11b0c7ac&scoped=true&"
import script from "./goods-app-staff.vue?vue&type=script&lang=js&"
export * from "./goods-app-staff.vue?vue&type=script&lang=js&"
import style0 from "./goods-app-staff.vue?vue&type=style&index=0&id=11b0c7ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b0c7ac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/songtao/yxb/web-project/web-helper/official-website/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11b0c7ac')) {
      api.createRecord('11b0c7ac', component.options)
    } else {
      api.reload('11b0c7ac', component.options)
    }
    module.hot.accept("./goods-app-staff.vue?vue&type=template&id=11b0c7ac&scoped=true&", function () {
      api.rerender('11b0c7ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/page/mobile/components/goods-app-staff.vue"
export default component.exports