<template>
  <div class="clo-goods--wrapper">
    <div class="clo-goods">
      <img class="clo-goods__image" :src="AppStaff" alt="">
      <div class="clo-goods__subtitle">- APP 业务员 -</div>
      <div class="clo-goods__content">
        <div>定位考勤；巡店拍照；傻瓜式销售；</div>
        <div>智能交款手机端下订单；库存变化；</div>
        <div>盘点品项价格记忆；查看线路规划；交易查询</div>
      </div>
    </div>
  </div>
</template>

<script>
import AppStaff from '@/assets/mobile/appstaff@2x.png';

export default {
  data() {
    return {
      AppStaff,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-goods--wrapper {
  box-sizing: border-box;
  background-color: #F7F7F7;
  .clo-goods {
    @include flex-column(center, flex-start);
    width: 100%;
    height: 100%;
    &__title {
      @include flex-row(space-between, flex-end);
      width: 100%;
      padding-top: 37px;
      &--left {
        @include font-init(21px, #121212, 700);
        .log-img {
          width: 260px;
          height: 34px;
        }
      }
      &--right {
        background-color: #F39900;
        width: 29px;
        height: 7px;
      }
    }
    &__image {
      width: 100%;
      height: 100%;
      margin-top: 55px;
    }
    &__image--normal {
      width: 100%;
      height: 100%;
      margin-top: 70px;
    }
    &__subtitle {
      @include font-init(19px, #121212, 700);
      @include flex-row(center, center);
      padding: 0 26px 0 22px;
      width: 100%;
      margin-top: 26px;
    }
    &__content {
      @include flex-column(flex-start, flex-start);
      @include font-init(16px, #121212, 400);
      padding: 0 26px 53px 22px;
      margin-top: 27px;
      line-height: 27px;
      letter-spacing: -1px;

      text-align: left;
    }
  }
}
</style>
