<template>
  <div class="clo-record--wrapper">
    <div class="clo-record">
      <div class="clo-record--business">© 2018-2019 yxbabc.com 版权所有</div>
      <a class="clo-record--service" href="https://beian.miit.gov.cn/" target="_blank">ICP证：浙ICP备18040182号-1</a>

    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-record--wrapper {
  @include flex-row(center, center);
  @include font-init(13px,#fff,500);
  width: 100%;
  height: 80px;
  background-color: #252E37;
  .clo-record {
    @include flex-column(center, center);
    line-height: 23px;
  }
}
</style>
