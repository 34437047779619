var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clo-send-message" }, [
    _c("div", { staticClass: "clo-send-message__form" }, [
      _c("div", { staticClass: "clo-send-message__form--item" }, [
        _c("img", {
          staticClass: "item__icon",
          attrs: { src: _vm.MessageName, alt: "", srcset: "" }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.dataForm.linkman,
              expression: "dataForm.linkman"
            }
          ],
          staticClass: "item__input",
          attrs: { type: "text", placeholder: "姓名" },
          domProps: { value: _vm.dataForm.linkman },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.dataForm, "linkman", $event.target.value)
            }
          }
        })
      ]),
      _c("div", { staticClass: "clo-send-message__form--item" }, [
        _c("img", {
          staticClass: "item__icon",
          attrs: { src: _vm.MessagePhone, alt: "", srcset: "" }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.dataForm.tel,
              expression: "dataForm.tel"
            }
          ],
          staticClass: "item__input",
          attrs: { type: "text", placeholder: "手机号" },
          domProps: { value: _vm.dataForm.tel },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.dataForm, "tel", $event.target.value)
            }
          }
        })
      ]),
      _c("div", { staticClass: "clo-send-message__form--item" }, [
        _c("img", {
          staticClass: "item__icon",
          attrs: { src: _vm.MessageMessage, alt: "", srcset: "" }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.dataForm.content,
              expression: "dataForm.content"
            }
          ],
          staticClass: "item__input",
          attrs: { type: "text", placeholder: "留言" },
          domProps: { value: _vm.dataForm.content },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.dataForm, "content", $event.target.value)
            }
          }
        })
      ]),
      _c(
        "div",
        {
          staticClass: "clo-send-message__form--submit",
          on: { click: _vm.handleSubmit }
        },
        [_vm._v("提交")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }