var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clo-title--wrapper", class: _vm.bgClassName },
    [
      _c("div", { staticClass: "clo-title" }, [
        _c("div", { staticClass: "clo-title--left" }, [
          _c("img", {
            class: _vm.imgClassName,
            attrs: { src: _vm.img, alt: "" }
          })
        ]),
        _c("div", { staticClass: "clo-title--right" })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }