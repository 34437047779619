<template>
  <div class="clo-goods--wrapper">
    <div class="clo-goods">
      <img class="clo-goods__image" :src="MiniProgram" alt="">
      <div class="clo-goods__subtitle">- 客户小程序 -</div>
      <div class="clo-goods__content">
        <div>把自己的店铺开到微信上，下游客户直接打开微信就可</div>
        <div>以进入店铺下单，这简直就是网购式订货.</div>
        <div>我们可以做的无纸化办公;客户的电子单据可以永久的</div>
        <div>保存,永远不丢失,省去了纸质单据的不好储存的问题,你</div>
        <div>也可以让你的客户扫下面的小程序码直接扫码加入。</div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniProgram from '@/assets/mobile/miniprogram@2x.png';

export default {
  data() {
    return {
      MiniProgram,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.clo-goods--wrapper {
  box-sizing: border-box;
  background-color: #F7F7F7;
  .clo-goods {
    @include flex-column(center, flex-start);
    width: 100%;
    height: 100%;
    &__image {
      width: 100%;
      height: 100%;
      margin-top: 55px;
    }
    &__subtitle {
      @include font-init(19px, #121212, 700);
      @include flex-row(center, center);
      width: 100%;
      margin-top: 26px;
      padding: 0 26px 0 22px;
    }
    &__content {
      @include flex-column(flex-start, flex-start);
      @include font-init(16px, #121212, 400);
      padding: 0 26px 53px 22px;
      margin-top: 30px;
      line-height: 27px;
      letter-spacing: -1px;

      text-align: left;
    }
  }
}
</style>
