<template>
  <div class="load-app--wrapper">
    <div class="load-app__list" style="padding-top: 35px;">
      <div class="load-app__list--item" v-for="(item, index) in navigationList" :key="index">
        <div class="item__icon">
          <img class="item__image" :src="item.icon" alt="" v-imageError>
        </div>
        <div class="item__text">{{item.text}}</div>
      </div>
    </div>
    <div class="load-app__list" style="padding-top: 30px;">
      <div class="load-app__list--item" v-for="(item, index) in navigationList1" :key="index">
        <div class="item__icon">
          <img class="item__image" :src="item.icon" alt="" v-imageError>
        </div>
        <div class="item__text">{{item.text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Android from '@/assets/mobile/load-app-android@2x.png';
import Iphone from '@/assets/mobile/load-app-iphone@2x.png';
import Website from '@/assets/mobile/load-app-website@2x.png';

export default {
  data() {
    return {
      navigationList: [
        {
          icon: Website,
          text: '公众号二维码',
        },
        {
          icon: Android,
          text: '安卓手机下载',
        },
      ],
      navigationList1: [
        {
          icon: Iphone,
          text: '苹果手机下载',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.load-app--wrapper {
  width: 100%;
  padding: 0 45px;
  box-sizing: border-box;
  background-color: #FFF;
  .load-app__list {
    @include flex-row(space-between);
    width: 100%;
    flex-wrap: wrap;
    &--item {
      @include flex-column(space-between);
      height: 100%;
      .item__icon {
        width: 152px;
        height: 152px;
        .item__image {
          width: 100%;
          height: 100%;
        }
      }
      .item__text {
        color: #121212;
        font-size: 18px;
        margin-top: 10px;
      }
    }
  }
}
</style>
