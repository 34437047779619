var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "cloTop",
      staticClass: "clo-top--wrapper",
      class: { headerFixed: _vm.headerFixed }
    },
    [
      _c("div", { staticClass: "clo-top" }, [
        _c("div", { staticClass: "clo-top__logo" }, [
          _c("img", {
            staticClass: "logo__image",
            attrs: { src: _vm.Logo, alt: "" }
          })
        ]),
        _c("div", { staticClass: "clo-top__operate" }, [
          _c("img", {
            staticClass: "clo-icon mg-r26",
            attrs: { src: _vm.Phone, alt: "" },
            on: { click: _vm.callPhone }
          }),
          _c("img", {
            staticClass: "clo-icon",
            attrs: { src: _vm.Group, alt: "" }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }